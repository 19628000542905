var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AppInfoForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _vm.pageType === 1
                    ? _c("el-form-item", { attrs: { label: "APP唯一标识" } }, [
                        _c("div", {
                          staticClass: "text",
                          domProps: { textContent: _vm._s(_vm.form.appCode) },
                        }),
                        _c("label", { staticClass: "tips" }, [
                          _vm._v(
                            "注：该字段需三端对应，操作之前请务必联系系统管理员"
                          ),
                        ]),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "APP唯一标识",
                            rules: [
                              {
                                required: true,
                                message: "请输入APP唯一标识",
                                trigger: "change",
                              },
                            ],
                            prop: "appCode",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.form.appCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "appCode", $$v)
                              },
                              expression: "form.appCode",
                            },
                          }),
                          _c("label", { staticClass: "tips" }, [
                            _vm._v(
                              "注：该字段需三端对应，操作之前请务必联系系统管理员"
                            ),
                          ]),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "APP名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入APP名称",
                            trigger: "change",
                          },
                        ],
                        prop: "appName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.appName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appName", $$v)
                          },
                          expression: "form.appName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "APP图标",
                        rules: [
                          {
                            required: true,
                            message: "请上传APP图标",
                            trigger: "change",
                          },
                        ],
                        prop: "appLogo",
                      },
                    },
                    [
                      _c("v-uploader", {
                        attrs: {
                          action: _vm.uploadURL,
                          imgUrls: _vm.form.appLogo,
                          imageWH: [512, 512],
                          fileSize: 1,
                        },
                        on: {
                          "update:imgUrls": function ($event) {
                            return _vm.$set(_vm.form, "appLogo", $event)
                          },
                          "update:img-urls": function ($event) {
                            return _vm.$set(_vm.form, "appLogo", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("label", [
                                  _vm._v("注：上传512x512px，且小于1M的图片"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm.pageType === 1
                    ? _c("el-form-item", { attrs: { label: "APP类型" } }, [
                        _c("div", {
                          staticClass: "text",
                          domProps: {
                            textContent: _vm._s(
                              _vm.appTypeMap[_vm.form.appType]
                            ),
                          },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "APP类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择APP类型",
                                trigger: "change",
                              },
                            ],
                            prop: "appType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.appTypeOps },
                            on: { change: _vm.appTypeChange },
                            model: {
                              value: _vm.form.appType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "appType", $$v)
                              },
                              expression: "form.appType",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm.isShowUrl()
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "APP下载地址",
                            rules: [
                              {
                                required: false,
                                message: "请输入APP下载地址",
                                trigger: "change",
                              },
                            ],
                            prop: "appDownloadUrl",
                          },
                        },
                        [
                          _c("div", { attrs: { id: "download_qrcode_img" } }),
                          _c("v-button", {
                            staticClass: "copy-button",
                            attrs: { text: "复制链接" },
                            on: { click: _vm.copeValue },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isShowConcernButton()
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "APP下载地址",
                            rules: [
                              {
                                required: false,
                                message: "请输入APP下载地址",
                                trigger: "change",
                              },
                            ],
                            prop: "appDownloadUrl",
                          },
                        },
                        [
                          _c("v-button", {
                            staticClass: "concern-button",
                            attrs: { text: "去关联" },
                            on: { click: _vm.concernApp },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属公司" } },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入", width: _vm.width },
                        model: {
                          value: _vm.form.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "companyName", $$v)
                          },
                          expression: "form.companyName",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v("注：该字段将显示在APP端"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版权说明" } },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入", width: _vm.width },
                        model: {
                          value: _vm.form.copyRight,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "copyRight", $$v)
                          },
                          expression: "form.copyRight",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v("注：该字段将显示在APP端"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "APP介绍" } },
                    [
                      _c("v-textarea", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.introduce,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "introduce", $$v)
                          },
                          expression: "form.introduce",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "渠道类型",
                        rules: [
                          {
                            required: true,
                            type: "array",
                            message: "请选择渠道类型",
                            trigger: "change",
                          },
                        ],
                        prop: "channelType",
                      },
                    },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          mode: "checkbox-plus",
                          options: _vm.computedChannelTypeOps,
                          value: _vm.form.channelType,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "channelType", $event)
                          },
                          onChange: _vm.channelTypeChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.list.length
                    ? _c(
                        "div",
                        _vm._l(_vm.form.list, function (item, index) {
                          return _c(
                            "div",
                            { key: `${item.channelType}_${index}` },
                            [
                              _c("div", {
                                staticClass: "title",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.getTitle(item.channelType)
                                  ),
                                },
                              }),
                              _vm.isNewItem(item)
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "APP编号",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入APP编号",
                                            trigger: "blur",
                                          },
                                        ],
                                        prop: "list." + index + ".mobileType",
                                      },
                                    },
                                    [
                                      _c("v-input-number", {
                                        attrs: {
                                          placeholder: "请输入",
                                          min: 1,
                                        },
                                        model: {
                                          value: item.mobileType,
                                          callback: function ($$v) {
                                            _vm.$set(item, "mobileType", $$v)
                                          },
                                          expression: "item.mobileType",
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需三端对应，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-form-item",
                                    { attrs: { label: "APP编号" } },
                                    [
                                      _c("div", {
                                        staticClass: "text",
                                        domProps: {
                                          textContent: _vm._s(item.mobileType),
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需三端对应，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ]
                                  ),
                              _vm.isAppType(item.channelType)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "极光appKey" } },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: item.jpushAppkey,
                                          callback: function ($$v) {
                                            _vm.$set(item, "jpushAppkey", $$v)
                                          },
                                          expression: "item.jpushAppkey",
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需有第三方平台生成，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAppType(item.channelType)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "极光masterscret" } },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: item.jpushMasterscret,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "jpushMasterscret",
                                              $$v
                                            )
                                          },
                                          expression: "item.jpushMasterscret",
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需有第三方平台生成，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAppType(item.channelType)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "高德地图Key" } },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: item.gaodeMapKey,
                                          callback: function ($$v) {
                                            _vm.$set(item, "gaodeMapKey", $$v)
                                          },
                                          expression: "item.gaodeMapKey",
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需有第三方平台生成，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAppType(item.channelType)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "QQKey" } },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: item.qqKey,
                                          callback: function ($$v) {
                                            _vm.$set(item, "qqKey", $$v)
                                          },
                                          expression: "item.qqKey",
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需有第三方平台生成，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAppType(item.channelType)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "微信Key" } },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: item.weChatKey,
                                          callback: function ($$v) {
                                            _vm.$set(item, "weChatKey", $$v)
                                          },
                                          expression: "item.weChatKey",
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需有第三方平台生成，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAppType(item.channelType)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "友盟Key" } },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: item.umengKey,
                                          callback: function ($$v) {
                                            _vm.$set(item, "umengKey", $$v)
                                          },
                                          expression: "item.umengKey",
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需有第三方平台生成，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAppType(item.channelType)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "百度OCRKey" } },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: item.beiduOCRKey,
                                          callback: function ($$v) {
                                            _vm.$set(item, "beiduOCRKey", $$v)
                                          },
                                          expression: "item.beiduOCRKey",
                                        },
                                      }),
                                      _c("label", { staticClass: "tips" }, [
                                        _vm._v(
                                          "注：该字段需有第三方平台生成，操作之前请务必联系系统管理员"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAppType(item.channelType)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "APP启动页" } },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: item.startUpImg,
                                          callback: function ($$v) {
                                            _vm.$set(item, "startUpImg", $$v)
                                          },
                                          expression: "item.startUpImg",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }