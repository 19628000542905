<template>
  <div class="AppInfoForm-wrapper">
    <form-panel ref="formPanel"
                :form="form"
                v-bind="submitConfig"
                :submitBefore="submitBefore"
                :submitSuccess="submitSuccess"
                @update="update">
      <col2-detail>
        <col2-block title="基础信息">

          <el-form-item v-if="pageType === 1"
                        label="APP唯一标识">
            <div class="text"
                 v-text="form.appCode" />
            <label class="tips">注：该字段需三端对应，操作之前请务必联系系统管理员</label>
          </el-form-item>
          <el-form-item v-else
                        label="APP唯一标识"
                        :rules="[{ required: true, message: '请输入APP唯一标识', trigger: 'change'}]"
                        prop="appCode">
            <v-input v-model="form.appCode"
                     placeholder="请输入" />
            <label class="tips">注：该字段需三端对应，操作之前请务必联系系统管理员</label>
          </el-form-item>

          <el-form-item label="APP名称"
                        :rules="[{ required: true, message: '请输入APP名称', trigger: 'change'}]"
                        prop="appName">
            <v-input v-model="form.appName"
                     placeholder="请输入" />
          </el-form-item>
          <el-form-item label="APP图标"
                        :rules="[{ required: true, message: '请上传APP图标', trigger: 'change' }]"
                        prop="appLogo">
            <v-uploader :action="uploadURL"
                        :imgUrls.sync="form.appLogo"
                        :imageWH="[512, 512]"
                        :fileSize="1">
              <template #tip>
                <label>注：上传512x512px，且小于1M的图片</label>
              </template>
            </v-uploader>
          </el-form-item>

          <el-form-item v-if="pageType === 1"
                        label="APP类型">
            <div class="text"
                 v-text="appTypeMap[form.appType]" />
          </el-form-item>
          <el-form-item v-else
                        label="APP类型"
                        :rules="[{ required: true, message: '请选择APP类型', trigger: 'change' }]"
                        prop="appType">
            <v-select v-model="form.appType"
                      :options="appTypeOps"
                      @change="appTypeChange" />
          </el-form-item>

          <el-form-item label="APP下载地址"
                        v-if="isShowUrl()"
                        :rules="[{ required: false, message: '请输入APP下载地址', trigger: 'change'}]"
                        prop="appDownloadUrl">
            <div id="download_qrcode_img" ></div>
            <v-button  text="复制链接" class="copy-button"  @click="copeValue"/>
          </el-form-item>
          <el-form-item label="APP下载地址"
                        v-if="isShowConcernButton()"
                        :rules="[{ required: false, message: '请输入APP下载地址', trigger: 'change'}]"
                        prop="appDownloadUrl">
            <v-button  text="去关联" class="concern-button"  @click="concernApp"/>
          </el-form-item>
          <el-form-item label="所属公司">
            <v-input v-model="form.companyName"
                     placeholder="请输入"
                     :width="width" />
            <label class="tips">注：该字段将显示在APP端</label>
          </el-form-item>
          <el-form-item label="版权说明">
            <v-input v-model="form.copyRight"
                     placeholder="请输入"
                     :width="width" />
            <label class="tips">注：该字段将显示在APP端</label>
          </el-form-item>
          <el-form-item label="APP介绍">
            <v-textarea v-model="form.introduce"
                        placeholder="请输入" />
          </el-form-item>

          <el-form-item label="渠道类型"
                        :rules="[{ required: true, type: 'array', message: '请选择渠道类型', trigger: 'change' }]"
                        prop="channelType">
            <checkbox-plus mode="checkbox-plus"
                           :options="computedChannelTypeOps"
                           :value.sync="form.channelType"
                           @onChange="channelTypeChange" />
          </el-form-item>

          <div v-if="form.list.length">
            <div v-for="(item, index) in form.list"
                 :key="`${item.channelType}_${index}`" >
              <div class="title"
                   v-text="getTitle(item.channelType)" ></div>
              <el-form-item v-if="isNewItem(item)"
                            label="APP编号"
                            :rules="[{ required: true, message: '请输入APP编号', trigger: 'blur'}]"
                            :prop="'list.' + index + '.mobileType'">
                <v-input-number v-model="item.mobileType"
                                placeholder="请输入"
                                :min="1" />
                <label class="tips">注：该字段需三端对应，操作之前请务必联系系统管理员</label>
              </el-form-item>
              <el-form-item v-else
                            label="APP编号">
                <div class="text"
                     v-text="item.mobileType" />
                <label class="tips">注：该字段需三端对应，操作之前请务必联系系统管理员</label>
              </el-form-item>
              <el-form-item label="极光appKey" v-if="isAppType(item.channelType)">
                <v-input v-model="item.jpushAppkey"
                         placeholder="请输入"
                         :width="width" />
                <label class="tips">注：该字段需有第三方平台生成，操作之前请务必联系系统管理员</label>
              </el-form-item>
              <el-form-item label="极光masterscret" v-if="isAppType(item.channelType)">
                <v-input v-model="item.jpushMasterscret"
                         placeholder="请输入"
                         :width="width" />
                <label class="tips">注：该字段需有第三方平台生成，操作之前请务必联系系统管理员</label>
              </el-form-item>
              <el-form-item label="高德地图Key" v-if="isAppType(item.channelType)">
                <v-input v-model="item.gaodeMapKey"
                         placeholder="请输入"
                         :width="width" />
                <label class="tips">注：该字段需有第三方平台生成，操作之前请务必联系系统管理员</label>
              </el-form-item>
              <el-form-item label="QQKey" v-if="isAppType(item.channelType)">
                <v-input v-model="item.qqKey"
                         placeholder="请输入"
                         :width="width" />
                <label class="tips">注：该字段需有第三方平台生成，操作之前请务必联系系统管理员</label>
              </el-form-item>
              <el-form-item label="微信Key" v-if="isAppType(item.channelType)">
                <v-input v-model="item.weChatKey"
                         placeholder="请输入"
                         :width="width" />
                <label class="tips">注：该字段需有第三方平台生成，操作之前请务必联系系统管理员</label>
              </el-form-item>
              <el-form-item label="友盟Key" v-if="isAppType(item.channelType)">
                <v-input v-model="item.umengKey"
                         placeholder="请输入"
                         :width="width" />
                <label class="tips">注：该字段需有第三方平台生成，操作之前请务必联系系统管理员</label>
              </el-form-item>
              <el-form-item label="百度OCRKey" v-if="isAppType(item.channelType)">
                <v-input v-model="item.beiduOCRKey"
                         placeholder="请输入"
                         :width="width" />
                <label class="tips">注：该字段需有第三方平台生成，操作之前请务必联系系统管理员</label>
              </el-form-item>
              
              <el-form-item label="APP启动页" v-if="isAppType(item.channelType)">
                <v-input v-model="item.startUpImg"
                         placeholder="请输入"
                         :width="width" />
              </el-form-item>

              <!-- <el-form-item label="App默认头像底图" v-if="isAppType(item.channelType)">
                <v-uploader
                  ref="appAvatar"
                  :action="uploadURL"
                  :imgUrls.sync="item.reproductionAppImage"
                  :limit="1"
                  :onSuccess="appAvatarSuccess"
                >
                  <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
              </v-uploader>
              </el-form-item>
              <el-form-item label="App水印默认图" v-if="isAppType(item.channelType)">
                <v-uploader
                  ref="appWater"
                  :action="uploadURL"
                  :imgUrls.sync="item.watermarkAppImage"
                  :limit="1"
                  :onSuccess="appWaterSuccess"
                >
                  <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
              </v-uploader>
              </el-form-item>
              <el-form-item label="App启动页" v-if="isAppType(item.channelType)">
                <v-uploader
                  ref="appStart"
                  :action="uploadURL"
                  :imgUrls.sync="item.startUpImg"
                  :limit="1"
                  :onSuccess="appStartSuccess"
                >
                  <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
              </v-uploader>
              </el-form-item>
              <el-form-item label="App引导页" v-if="isAppType(item.channelType)">
                <v-uploader
                  ref="appGuide"
                  :action="uploadURL"
                  :imgUrls.sync="item.guideImg"
                  :limit="4"
                  :onSuccess="appGuideSuccess"
                >
                  <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
              </v-uploader>
              </el-form-item>
              <el-form-item label="运营位banner图" v-if="isAppType(item.channelType)">
                <v-uploader
                  ref="bannerImage"
                  :action="uploadURL"
                  :imgUrls.sync="item.bannerImage"
                  :limit="1"
                  :onSuccess="bannerImageSuccess"
                >
                  <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
              </v-uploader>
              </el-form-item> -->
            </div>
          </div>

        </col2-block>

      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { uploadURL } from 'common/api'
import { updateURL, queryDetailURL } from './api'
import { Col2Block, Col2Detail, CheckboxPlus } from '@/components/bussiness'
import { vUploader } from 'components/control'
import { setAppTypeOps, setChannelTypeOps, setIsForceOps, appTypeMap, channelTypeMap } from './map'
import _ from 'lodash'
import QRCode from 'qrcodejs2'

export default {
  name: 'AppInfoForm',
  components: {
    Col2Block,
    Col2Detail,
    CheckboxPlus,
    vUploader
  },
  data () {
    return {
      width: 500,
      pageType: 0,
      hasAppCheckedAndHasUrl: false,
      hasAppCheckedAndNoUrl: false,
      isCreate: false,
      submitConfig: {
        queryUrl: queryDetailURL,
        submitUrl: updateURL
      },
      uploadURL: `${uploadURL}?module=icon`,
      appTypeMap,
      qrCodeUrl: '',
      appTypeOps: setAppTypeOps(),
      isForceOps: setIsForceOps(),
      channelTypeOps: [],
      computedChannelTypeOps: [],
      form: {
        id: undefined,
        appCode: '',
        appName: '',
        appLogo: '',
        appType: undefined,
        appDownloadUrl: '',
        appDownloadUrlShort: '',
        companyName: '',
        copyRight: '',
        introduce: '',
        channelType: [],
        list: []
      }
    }
  },
  created () {
    const channelTypeOps = setChannelTypeOps().map(item => {
      return {
        label: item.text,
        value: item.value
      }
    })
    this.channelTypeOps = channelTypeOps
    this.computedChannelTypeOps = channelTypeOps
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.pageType = 1
      this.$refs.formPanel.getData({
        id
      })
    } else {
      this.isCreate = true
      this.$setBreadcrumb('新增')
      this.form.appType = this.appTypeOps[0].value
    }
  },
  // 1.新增的时候，隐藏app下载地址和app下载短地址两个字段
  // 2.编辑的时候，默认也是隐藏的，除非勾选了前三个中的任意一个，则做回显，否则也是不显示的
  // 3.在app下载地址后方，增加一个点击可查看二维码的交互，支持直接扫码下载app
  // 4.当用户将下载模板关联到app的时候，服务端自动生成app下载地址和app下载短地址，方便后续编辑的时候做回显
  // 5.将app下载地址和app下载短地址合并为一个字段：app下载二维码，并直接显示下载二维码，并增加icon（点击，复制链接）
  // 6.（如果勾选了app）保存完，自动生成一个默认的下载模板，并关联到当前app，同时生成app的下载长/短地址
  methods: {
    isShowUrl () {
      if (this.pageType === 0) {
        return false
      } else {
        if (this.hasAppCheckedAndHasUrl) {
          return true
        } else {
          return false
        }
      }
    },
    // 是否显示去关联按钮
    // 新增不显示
    isShowConcernButton () {
      if (this.pageType === 0) {
        return false
      } else {
        if (this.hasAppCheckedAndNoUrl) {
          return true
        } else {
          return false
        }
      }
    },
    genQRCode (source) {
      let dom = document.getElementById('download_qrcode_img')
      if (dom) {
        while (dom.firstChild) {
          dom.removeChild(dom.firstChild)
        }
        let id = `download_qrcode_img`
        this.$nextTick(() => {
          new QRCode(id, { // eslint-disable-line
            width: 200,
            height: 200,
            text: source
          })
        }, 20)
      }
    },
    appTypeChange (val) {
      let channelTypeOps = _.cloneDeep(this.channelTypeOps) || []
      let channelType = this.form.channelType || []
      if (val === 4) {
        this.form.channelType = channelType.filter(it => {
          return it === 1
        })
        this.computedChannelTypeOps = channelTypeOps.filter((item, index) => {
          return item.value === 1
        })
        this.computedChannelTypeOps.forEach((item, index) => {
          if (item.value !== 1) {
            this.channelTypeChange(index, this.form.channelType)
          }
        })
      } else {
        this.computedChannelTypeOps = this.channelTypeOps
      }
    },
    isNewItem (item) {
      return item.hasOwnProperty('newItem')
    },
    isAppType (type) {
      return type === 1 || type === 2 || type === 3
    },
    copeValue () {
      let el = document.createElement('input')
      el.value = `  ${this.qrCodeUrl}  `
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      this.$message({
        message: '复制成功',
        type: 'success',
        duration: 1000
      })
      document.body.removeChild(el)
    },
    // 关联app下载模板
    concernApp () {
      const newAppData = {
        appCode: this.form.appCode,
        appLogo: this.form.appLogo,
        businessType: 2,
        businessTypeName: 'H5模板',
        mouldType: 'download_page_mould',
        mouldTypeName: '下载页面'
      }
      this.$router.push({
        name: 'templateForm',
        query: {
          newAppData: newAppData
        }
      })
    },
    async submitSuccess (data, msg) {
      console.log('submitSuccess')
      if (this.needDialog()) {
        let isOk = await this.$confirm('APP新增成功，关联下载页面?', {
          cancelButtonText: '暂不关联',
          confirmButtonText: '去关联'
        })
        if (isOk) {
          this.concernApp(true)
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.go(-1)
      }
      return false
    },
    needDialog () {
      console.log('needDialog', 'channelType', this.form.channelType)
      let result = false
      if (this.form.channelType) {
        this.form.channelType.forEach(item => {
          if (item === 1 || item === 2 || item === 3) {
            console.log('needDialog', 'item', item)
            console.log('isCreate', this.isCreate)
            if (this.isCreate) {
              result = true
            }
          }
        })
      }
      console.log('result', result)
      return result
    },
    isNotNull (str) {
      if (typeof str === 'string' && str.trim().length > 0) {
        return true
      } else {
        return false
      }
    },
    getAppTypeText (cType) {
      if (cType === 1) {
        return 'Android'
      } else if (cType === 2) {
        return 'iOS商店'
      } else if (cType === 3) {
        return 'iOS企业'
      } else if (cType === 4) {
        return '微信小程序'
      } else if (cType === 5) {
        return '支付宝小程序'
      } else if (cType === 6) {
        return 'H5'
      }
    },
    update (data) {
      if (data && data.list && data.list.length) {
        data['channelType'] = []
        data.list.forEach(item => {
          const mChannelType = item.channelType
          console.log('mChannelType', mChannelType)
          item.guideImg = eval(item.guideImg) || []
          if (mChannelType === 1 || mChannelType === 2 || mChannelType === 3) {
            if (this.isNotNull(data.appDownloadUrlShort) || this.isNotNull(data.appDownloadUrl)) {
              this.hasAppCheckedAndHasUrl = true
            } else {
              this.hasAppCheckedAndNoUrl = true
            }
          }
          const channelType = this.computedChannelTypeOps.find(channelTypeOp => {
            return channelTypeOp.value === item.channelType
          })
          if (channelType) {
            channelType.disabled = true
          }
          data['channelType'].push(item.channelType)
        })
      }
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.appTypeChange(this.form.appType)
      if (this.isNotNull(data.appDownloadUrlShort)) {
        this.qrCodeUrl = data.appDownloadUrlShort
      } else if (this.isNotNull(data.appDownloadUrl)) {
        this.qrCodeUrl = data.appDownloadUrl
      }
      if (this.isNotNull(this.qrCodeUrl)) {
        setTimeout(() => {
          this.genQRCode(this.qrCodeUrl)
        }, 500)
      }
    },
    async submitBefore (data) {
      if (this.form.id !== undefined) {
        const result = await this.$confirm('修改APP配置信息后，将会影响用户使用和下载，请谨慎操作。')
        if (!result) {
          return false
        }
      } else {
        // 唯一性校验, 去掉前后空格
        data.appCode = data.appCode.trim() // APP唯一标识
      }
      if (data.list && data.list.length) {
        data.list.forEach(item => {
          item.appName = `${data.appName}-${this.getAppTypeText(item.channelType)}`
        })
      }
      let dataObject = `${data.appName}-${data.appCode}`
      this.$set(data, 'dataObject', dataObject)
      return true
    },
    getTitle (channelType) {
      return channelTypeMap[channelType]
    },
    channelTypeChange (index, ops) {
      const value = this.channelTypeOps[index].value
      if (ops.indexOf(value) === -1) {
        const i = this.form.list.findIndex(item => {
          return item.channelType === value
        })
        this.form.list.splice(i, 1)
      } else {
        this.form.list.push({
          newItem: undefined,
          channelType: value,
          id: '',
          mobileType: undefined,
          jpushAppkey: '',
          jpushMasterscret: '',
          startUpImg: '',
          reproductionAppImage: '',
          watermarkAppImage: '',
          guideImg: '',
          bannerImage: ''
        })
      }
    },
    appAvatarSuccess () {},
    appWaterSuccess () {},
    appStartSuccess () {},
    appGuideSuccess (val) {
      console.log(this.form, val);
    },
    bannerImageSuccess () {},
  }
}
</script>

<style lang="scss" scoped>
.AppInfoForm-wrapper {
  .text {
    display: inline-block;
    min-width: 144px;
  }
  .tips {
    margin-left: 20px;
  }
  .title {
    width: 742px;
    background-color: #e3e8ef;
    padding: 5px 0 5px 5px;
    margin-bottom: 20px;
  }
}
.qrcode_bg {
  width: 360px;
  height: 360px;
  background: #67c23a;
}
.copy-button {
  width: 200px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.concern-button {
  width: 200px;
  margin-bottom: 20px;
}
</style>
